import { apiClient } from "@netgame/openapi";
import { toast } from "vue3-toastify";

import useAppInitData from "./useAppInitData";
import useIsGuest from "./useIsGuest";
import useIsRegistrationCompleteGuard from "./useIsRegistrationCompleteGuard";

type ModalClose<K extends string> = (name: K) => void;

type LoginUserProps = {
	method: "apple" | "google" | "facebook";
	token: string;
};

const useOAuth = <K extends string>({
	closeAll,
	open,
	onConnectAccount
}: {
	open?: ModalClose<K>;
	closeAll?: () => void;
	onConnectAccount?: (method: string) => void;
}) => {
	const { data: appInit, refresh } = useAppInitData();
	const { isDesktop } = useDevice();
	const playerSocLogin = computed(() => appInit?.value?.playerSocLogin);
	const isGuest = useIsGuest();
	const FACEBOOK_CLIENT_ID = computed(() => appInit?.value?.playerSocLogin?.credentials?.facebook?.keys?.id);
	const GOOGLE_CLIENT_ID = computed(() => appInit?.value?.playerSocLogin?.credentials?.google?.keys?.id);
	const APPLE_ID = computed(() => appInit?.value?.playerSocLogin?.credentials?.apple?.keys?.id);
	const APPLE_REDIRECT_URI = computed(() => appInit?.value?.playerSocLogin?.credentials?.apple?.callback);
	const isLoading = ref(true);
	const APPLE_AUTH_SCOPE = "name email";
	const GOOGLE_SCRIPT_ID = "google-auth";
	const FACEBOOK_SCRIPT_ID = "facebook-auth";
	const APPLE_SCRIPT_ID = "apple-auth";
	const isConnectAccount = !!onConnectAccount;
	const guard = useIsRegistrationCompleteGuard();
	const {
		public: { fbVersion }
	} = useRuntimeConfig();

	const loginUser = async ({ method, token }: LoginUserProps) => {
		const res = await apiClient({
			path: `/rest/login/${method}/`,
			method: "post",
			parameters: {
				body: {
					...((method === "google" || method === "facebook") && { accessToken: token }),
					...(method === "apple" && { code: token }),
					needToLogin: !isConnectAccount
				}
			}
		});

		if (res?.success) {
			if (onConnectAccount) {
				onConnectAccount(method);
				return;
			}

			if (closeAll) {
				closeAll();
			}

			dispatchGAEvent({
				event: "auth",
				button_name: method
			});

			isGuest.value = false;
			window.scrollTo({ top: 0, behavior: "instant" as ScrollBehavior });
			clearNuxtData("/rest/app/init/");

			await refresh();

			const cookie = useCookie("showWelcomePopup", {
				expires: new Date(Date.now() + 365 * 24 * 60 * 60 * 1000),
				path: "/"
			});
			cookie.value = "true";

			guard({
				success: () => {
					if (open) {
						open("LazyOModalRegistrationComplete" as K);
					}
				}
			});
			return;
		}

		if (res.statusCode === 400 && res.data?.message) {
			dispatchGAEvent({
				event: "oauth_error",
				method,
				description: `${res.data?.message}`
			});

			toast.error(res.data?.message, {
				theme: toast.THEME.DARK,
				position: isDesktop ? toast.POSITION.BOTTOM_RIGHT : toast.POSITION.BOTTOM_CENTER,
				transition: toast.TRANSITIONS.SLIDE,
				autoClose: 5000
			});
		}
	};

	onMounted(() => {
		const googleAuthScript = window?.document?.getElementById(GOOGLE_SCRIPT_ID);
		const facebookAuthScript = window?.document?.getElementById(FACEBOOK_SCRIPT_ID);
		const appleAuthScript = window?.document?.getElementById(APPLE_SCRIPT_ID);

		if (!googleAuthScript) {
			const authScript = document.createElement("script");
			authScript.id = GOOGLE_SCRIPT_ID;
			authScript.src = "https://accounts.google.com/gsi/client";
			document.body.appendChild(authScript);
			setTimeout(() => {
				window?.google?.accounts?.id?.initialize({
					client_id: GOOGLE_CLIENT_ID.value
				});
			}, 1000);
		}

		if (!facebookAuthScript) {
			const authScript = document.createElement("script");
			authScript.id = FACEBOOK_SCRIPT_ID;
			authScript.textContent = `(function(d, s, id){
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {return;}
      js = d.createElement(s); js.id = id;
      js.src = "//connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));`;
			document.body.appendChild(authScript);
		}

		if (!appleAuthScript) {
			const authScript = document.createElement("script");
			authScript.id = APPLE_SCRIPT_ID;
			authScript.src = "https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js";
			document.body.appendChild(authScript);
		}

		isLoading.value = false;
	});

	const facebookAuth = () => {
		try {
			dispatchGAEvent({
				event: "click_button",
				button_name: "facebook_auth"
			});

			window?.FB?.init({
				appId: FACEBOOK_CLIENT_ID.value || "",
				xfbml: true,
				version: fbVersion || "v18.0"
			});

			window?.FB?.login(
				(response) => {
					if (response?.authResponse) {
						loginUser({ method: "facebook", token: response?.authResponse?.accessToken });
					}
				},
				{ scope: "public_profile,email" }
			);
		} catch (e) {
			const errorMsg = JSON.stringify(e);

			dispatchGAEvent({
				event: "oauth_error",
				location: "auth",
				method: "facebook",
				description: errorMsg
			});
			console.error("Facebook oauth: ", errorMsg);
		}
	};

	const googleAuth = () => {
		try {
			dispatchGAEvent({
				event: "click_button",
				button_name: "google_auth"
			});

			const client = window?.google?.accounts?.oauth2?.initTokenClient({
				client_id: GOOGLE_CLIENT_ID.value || "",
				scope: "profile email",
				callback: (response) => {
					loginUser({ method: "google", token: response?.access_token });
				}
			});

			client.requestAccessToken();
		} catch (e) {
			const errorMsg = JSON.stringify(e);

			dispatchGAEvent({
				event: "oauth_error",
				location: "auth",
				method: "google",
				description: errorMsg
			});

			console.error("Facebook oauth: ", errorMsg);
		}
	};

	const appleAuth = async () => {
		try {
			dispatchGAEvent({
				event: "click_button",
				button_name: "apple_auth"
			});

			window?.AppleID?.auth.init({
				clientId: APPLE_ID.value,
				redirectURI: APPLE_REDIRECT_URI.value,
				scope: APPLE_AUTH_SCOPE || "",
				usePopup: true
			});
			const data = await window?.AppleID?.auth?.signIn();

			loginUser({ method: "apple", token: data?.authorization?.code });
		} catch (e) {
			const errorMsg = JSON.stringify(e);

			dispatchGAEvent({
				event: "oauth_error",
				location: "auth",
				method: "apple",
				description: errorMsg
			});

			console.error("Apple oauth: ", errorMsg);
		}
	};

	const handleOAuth = (type: string) => {
		if (type === "apple") {
			appleAuth();
			return;
		}
		if (type === "google") {
			googleAuth();
			return;
		}
		if (type === "facebook") {
			facebookAuth();
		}
	};

	return {
		isLoading,
		playerSocLogin,
		handleOAuth
	};
};

export default useOAuth;
