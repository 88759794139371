import { useStorage } from "@vueuse/core";

import useAppInitData from "./useAppInitData";
import useOAuth from "./useOAuth";
import useRegistrationType from "./useRegistrationType";

export const socials = [
	{
		id: "gp",
		name: "google",
		href: "/login/Google/",
		icon: "google",
		type: "googleAuthEnabled"
	},
	{
		id: "fb",
		name: "facebook",
		href: "/login/facebook/",
		icon: "fb",
		type: "facebookAuthEnabled"
	}
] as const;

type ModalOpen<K extends string, P> = (name: K, props?: P) => void;

type SocialMethod = "google" | "facebook" | "apple";

const useAuthSocialButtons = <K extends string, P>({
	checkReRegister = false,
	openInWindow = false,
	onConnectAccount,
	closeAll,
	open
}: {
	checkReRegister?: boolean;
	openInWindow?: boolean;
	onConnectAccount?: (method: string) => void;
	open?: ModalOpen<K, P>;
	closeAll?: () => void;
} = {}) => {
	const { data: appInit } = useAppInitData();
	const registrationType = useRegistrationType();
	const { playerSocLogin, isLoading, handleOAuth } = useOAuth({ onConnectAccount, closeAll, open });
	const promoOfferStorage = useStorage("promoOffer", "");
	const route = useRoute();

	const activeSocialAccounts = computed(() => new Set(appInit.value?.activeSocialAccounts));

	const enabledButtons = computed(
		() =>
			appInit.value?.registrationFormData || {
				emailEnabled: true,
				phoneEnabled: true,
				facebookAuthEnabled: true,
				googleAuthEnabled: true,
				contactsOrder: ["email", "phone"]
			}
	);

	const socialMethods = computed(() => {
		let buttons;

		if (playerSocLogin.value?.enabled) {
			buttons = socials.filter(({ name }) => {
				const methodData = playerSocLogin.value?.credentials?.[name as SocialMethod];
				return methodData?.enabled;
			});
		} else {
			buttons = socials.filter(({ type }) => !!enabledButtons.value[type]);
		}

		return buttons.map((method) => {
			const data = playerSocLogin.value?.credentials?.[method.name as SocialMethod];
			return {
				data,
				...method
			};
		});
	});

	const handleButtonClick = ({ name, href }: { name: SocialMethod; href?: string }) => {
		console.log("name :>> ", name);
		dispatchGAEvent({
			event: "click_button",
			button_name: `oauth_click-${name}`,
			location: "profile"
		});

		const { promoOffer } = route.query;

		if (promoOffer && !Array.isArray(promoOffer)) {
			promoOfferStorage.value = promoOffer;
		}

		if (checkReRegister) {
			if (registrationType.value && open) {
				open(
					"LazyOModalReRegistration" as K,
					{
						newType: name,
						onCreateAccountClick: playerSocLogin.value?.enabled ? () => handleOAuth(name) : undefined
					} as P
				);
				return;
			}
			registrationType.value = name;
		}

		if (playerSocLogin.value?.enabled) {
			handleOAuth(name);
			return;
		}

		if (!href) {
			return;
		}

		if (openInWindow && !activeSocialAccounts.value.has(name)) {
			window.open(href, name, "width=865,height=665");
			return;
		}

		window.location.href = href;
	};

	return {
		socialMethods,
		isLoading,
		playerSocLogin,
		handleButtonClick
	};
};

export default useAuthSocialButtons;
